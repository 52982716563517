import React, { useEffect, useState, FunctionComponent } from 'react';
import {
  CategoryFacet as HeadlessCategoryFacet,
  CategoryFacetValue,
  Facet as HeadlessFacet,
} from '@coveo/headless';
import './index.scss';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Facet } from './facet.fn';

interface DependentFacetMapping {
  dependentFacet: HeadlessFacet;
  dependentValues: string[];
  title: string;
}

interface CategoryFacetProps {
  controller: HeadlessCategoryFacet;
  dependentFacets: DependentFacetMapping[];
}

const CUSTOM_ORDER = ['Hospitals', 'Cancer Centres', 'Sports Medicine Clinics', 'Outreach Centre'];

export const CategoryFacet: FunctionComponent<CategoryFacetProps> = ({
  controller,
  dependentFacets,
}) => {
  const [state, setState] = useState(controller.state);
  const [visibleDependentFacets, setVisibleDependentFacets] = useState<DependentFacetMapping[]>([]);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);

  useEffect(() => {
    const updateVisibleDependentFacets = () => {
      const selectedValues: string[] = [];

      const collectSelectedValues = (values: CategoryFacetValue[]) => {
        values.forEach((value) => {
          if (value.state === 'selected') {
            selectedValues.push(value.value);
          }
          if (value.children.length > 0) {
            collectSelectedValues(value.children);
          }
        });
      };

      collectSelectedValues(state.values);
      collectSelectedValues(state.parents);

      const visibleFacets = dependentFacets.filter(({ dependentValues }) =>
        dependentValues.some((value) => selectedValues.includes(value))
      );

      setVisibleDependentFacets(visibleFacets);
    };

    updateVisibleDependentFacets();
  }, [state, dependentFacets]);

  function getUniqueKeyForValue(value: CategoryFacetValue) {
    return value.path.join('>');
  }

  function clearAllFacets() {
    controller.deselectAll();
    dependentFacets.forEach(({ dependentFacet }) => {
      dependentFacet.deselectAll();
    });
    setVisibleDependentFacets([]);
  }

  function renderClearButton() {
    return (
      <>
        <ArrowBackIosIcon className="category-arrow-icon-back" />
        <Button className="category-facet-categories" variant="text" onClick={clearAllFacets}>
          Go Back to All Locations
        </Button>
      </>
    );
  }

  function renderActiveValues() {
    // Sort values according to the custom order
    const sortedValues = [...state.values].sort((a, b) => {
      return CUSTOM_ORDER.indexOf(a.value) - CUSTOM_ORDER.indexOf(b.value);
    });

    return (
      <div>
        {sortedValues.map((value) => (
          <div key={getUniqueKeyForValue(value)}>
            <Button
              className="category-facet-categories"
              variant="text"
              onClick={() => controller.toggleSelect(value)}
            >
              {value.value}
            </Button>
            <ArrowForwardIosIcon className="category-arrow-icon" />
          </div>
        ))}
      </div>
    );
  }

  function renderDependentFacets() {
    return visibleDependentFacets.map(({ dependentFacet, title }, index) => (
      <div className="dependent-facet-wrapper" key={index}>
        <span className="facet-title">{title}</span>
        <Facet controller={dependentFacet} />
      </div>
    ));
  }

  return (
    <div>
      <div>
        {renderActiveValues()}
        {visibleDependentFacets.length > 0 && renderDependentFacets()}
      </div>
      {state.hasActiveValues && renderClearButton()}
    </div>
  );
};
