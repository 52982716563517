import React, { useEffect } from 'react';
import { Field, SitecoreContext, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Grid from '@material-ui/core/Grid';

import './index.scss';
import {
  buildSearchBox,
  buildResultList,
  buildPager,
  buildQuerySummary,
  buildDidYouMean,
  buildFacet,
  buildCategoryFacet,
  CategoryFacetValue,
  buildSort,
  loadSearchAnalyticsActions,
  loadSearchActions,
  loadAdvancedSearchQueryActions,
  Sort as HeadlessSort,
  SortOrder,
  SortCriterion,
  buildFieldSortCriterion,
} from '@coveo/headless';
import config from '../../../temp/config';
import HeadlessEngine from '../lib/engine';
import { ResultList } from '../lib/resultlist';
import { Pager } from '../lib/pager';
import { QuerySummary } from '../lib/querysummary';
import { DidYouMean } from '../lib/didyoumean';
import { UrlManager } from '../lib/utils';
import { ConsultantResult } from '../lib/resulttemplates/consultantresult';
import { SearchBox } from '../lib/searchbox';
import { Facet } from '../lib/facet';
import { Sort } from '../lib/sort';
import { useLocation } from 'react-router-dom';
import { MultipleValueDependentFacet } from '../lib/locationsfacet/multiplevaluedependentfacet';

type SearchPageProps = {
  fields: {
    Heading: Field<string>;
  };
  sitecoreContext: SitecoreContext & { pageEditing: boolean; custom: any };
};

const headlessEngine = new HeadlessEngine(config.coveoQueryPipeline, 'Consultants').engine;
const SearchPage = ({ sitecoreContext }: SearchPageProps): JSX.Element => {
  const searchBox = buildSearchBox(headlessEngine);
  const querySummary = buildQuerySummary(headlessEngine);
  const didYouMean = buildDidYouMean(headlessEngine);
  const criteria: [string, SortCriterion][] = [
    ['Last Name (Ascending)', buildFieldSortCriterion('lastz32xname', SortOrder.Ascending)],
  ];
  const initialCriterion = criteria[0][1];
  const controller = buildSort(headlessEngine, {
    initialState: { criterion: initialCriterion },
  });
  const Location = useLocation();

  const resultList = buildResultList(headlessEngine, {
    options: {
      fieldsToInclude: [
        'consultantimage',
        'consultantlocations',
        'consultantphonenumbers',
        'consultantspecialties',
        'consultantsubspecialties',
        'displayz32xname',
        'consultanthierarchicallocations',
        'consultanthospitals',
        'consultantcancercentres',
        'consultantsportsmedicineclinics',
        'consultantoutreachcentres',
      ],
    },
  });
  const pager = buildPager(headlessEngine);
  const specFacetDesktop = buildFacet(headlessEngine, {
    options: {
      field: 'consultantspecialties',
      facetId: 'specFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });
  const specFacetMobile = buildFacet(headlessEngine, {
    options: {
      numberOfValues: 5,
      field: 'consultantspecialties',
      facetId: 'specFacetMobile',
      sortCriteria: 'alphanumeric',
    },
  });

  // new facets
  const HierarchicalLocFacetDesktop = buildCategoryFacet(headlessEngine, {
    options: {
      numberOfValues: 12,
      field: 'consultanthierarchicallocations',
      facetId: 'hierarchicalLocFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });
  const HospitalFacetDesktop = buildFacet(headlessEngine, {
    options: {
      numberOfValues: 12,
      field: 'consultanthospitals',
      facetId: 'hospitalLocFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });
  const CancerCentresFacetDesktop = buildFacet(headlessEngine, {
    options: {
      numberOfValues: 12,
      field: 'consultantcancercentres',
      facetId: 'cancercentresLocFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });
  const SportsClinicsFacetDesktop = buildFacet(headlessEngine, {
    options: {
      numberOfValues: 12,
      field: 'consultantsportsmedicineclinics',
      facetId: 'sportsMedLocFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });
  const outreachFacetDesktop = buildFacet(headlessEngine, {
    options: {
      numberOfValues: 12,
      field: 'consultantoutreachcentres',
      facetId: 'outreachFacetDesktop',
      sortCriteria: 'alphanumeric',
    },
  });

  // new facets

  function getQueryExpression(sitecoreContext: any) {
    const database = sitecoreContext?.route?.databaseName;
    var currentIndex = 'Coveo_' + database + '_index';
    var siteName = sitecoreContext?.site?.name;
    var sysSource = currentIndex + ' - ' + config.coveoFarmName;
    var currentLanguage = sitecoreContext?.language;
    var languageQuery = '@language==' + currentLanguage;
    var versionQuery = ' @z95xlatestversion==1';
    var siteQuery = ' @site==' + siteName;
    var sourceQuery = ' @syssource=="' + sysSource;
    var templateQuery = '" @z95xtemplatename==("ConsultantDetailsContent")';
    var hideFromSearch = ' @hidez32xfromz32xsearch==0';
    var nonMediaQuery =
      '(' +
      languageQuery +
      versionQuery +
      siteQuery +
      sourceQuery +
      templateQuery +
      hideFromSearch +
      ')';
    var queryExpression = '(' + nonMediaQuery + ')';
    return queryExpression;
  }

  useEffect(() => {
    const { logInterfaceLoad } = loadSearchAnalyticsActions(headlessEngine);
    const { executeSearch } = loadSearchActions(headlessEngine);

    const cqaction = loadAdvancedSearchQueryActions(headlessEngine).registerAdvancedSearchQueries({
      cq: getQueryExpression(sitecoreContext),
    });

    headlessEngine.dispatch(cqaction);
    headlessEngine.dispatch(executeSearch(logInterfaceLoad()));
    UrlManager(headlessEngine);
  });

  // clears, submits new search which defaults to all. If user is navigating consultants, the clear will not occur
  useEffect(() => {
    const SpecialistsSubfolder = /\/consultants/;
    var currentUrl = window.location.href;
    if (!SpecialistsSubfolder.test(window.location.pathname)) {
      // update the search value and submit a new search
      searchBox.updateText('');
      searchBox.clear();
      // cleans up sort query in url that travels into next page
      currentUrl = currentUrl.split('#')[0];
      window.location.href = currentUrl;
    }
  }, [Location]);

  return (
    <div className="headless-consultants-search-page">
      {sitecoreContext.pageEditing && (
        <>
          <p>Consultants SearchPage Component Edit Mode</p>
        </>
      )}
      <div className="search-box-container">
        <SearchBox
          controller={searchBox}
          placeholderText="Search by first name, last name, specialty, or location"
        />
        <hr />
        <Grid container alignItems="stretch" className="two-column-content-row content-row" xs={12}>
          <Grid item xs={12} md={3}>
            <div className="facetDesktop">
              <MultipleValueDependentFacet
                engine={headlessEngine}
                parentFacet={HierarchicalLocFacetDesktop}
                dependentFacets={[
                  {
                    dependentFacet: HospitalFacetDesktop,
                    dependentValues: ['Hospitals'],
                    title: 'Hospitals',
                  },
                  {
                    dependentFacet: CancerCentresFacetDesktop,
                    dependentValues: ['Cancer Centres'],
                    title: 'Cancer Centres',
                  },
                  {
                    dependentFacet: SportsClinicsFacetDesktop,
                    dependentValues: ['Sports Medicine Clinics'],
                    title: 'Sports Medicine Clinics',
                  },
                  {
                    dependentFacet: outreachFacetDesktop,
                    dependentValues: ['Outreach Centre'],
                    title: 'Outreach Centres',
                  },
                ]}
              />
            </div>
            <div className="facetMobile">
              <MultipleValueDependentFacet
                engine={headlessEngine}
                parentFacet={HierarchicalLocFacetDesktop}
                dependentFacets={[
                  {
                    dependentFacet: HospitalFacetDesktop,
                    dependentValues: ['Hospitals'],
                    title: 'Hospitals',
                  },
                  {
                    dependentFacet: CancerCentresFacetDesktop,
                    dependentValues: ['Cancer Centres'],
                    title: 'Cancer Centres',
                  },
                  {
                    dependentFacet: SportsClinicsFacetDesktop,
                    dependentValues: ['Sports Medicine Clinics'],
                    title: 'Sports Medicine Clinics',
                  },
                  {
                    dependentFacet: outreachFacetDesktop,
                    dependentValues: ['Outreach Centre'],
                    title: 'Outreach Centres',
                  },
                ]}
              />
            </div>

            <div className="facetDesktop">
              <Facet controller={specFacetDesktop} title="Specialties" showCount={false} />
            </div>
            <div className="facetMobile">
              <Facet controller={specFacetMobile} title="Specialties" showCount={false} />
            </div>
          </Grid>
          <Grid item xs={12} md={9} direction="row" spacing={1}>
            <div className="mainresults-area consultant-listing">
              <Grid container alignItems="stretch">
                <div className="search-title">
                  <QuerySummary controller={querySummary} />
                  <DidYouMean controller={didYouMean} />
                </div>
                <ResultList
                  controller={resultList}
                  hostName={sitecoreContext?.custom?.targetHostName}
                  engine={headlessEngine}
                >
                  <ConsultantResult engine={headlessEngine}></ConsultantResult>
                </ResultList>
              </Grid>
            </div>
            <div className="footer-area">
              <Pager controller={pager} />
            </div>
            <div className="hidden">
              <Sort controller={controller} criteria={criteria} />;
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withSitecoreContext()(SearchPage);
