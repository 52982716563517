import React from 'react';
import { Facet as HeadlessFacet } from '@coveo/headless';
import { FunctionComponent, useEffect, useState } from 'react';
import { ExpandMoreRounded, ExpandLessRounded } from '@material-ui/icons';
import { Button } from '@material-ui/core';
interface FacetProps {
  controller: HeadlessFacet;
  title: string;
  showCount: boolean;
}

export const Facet: FunctionComponent<FacetProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), [controller]);
  const getShowMore = () => {
    return (
      <Button
        color="secondary"
        variant="outlined"
        title="Show More"
        onClick={() => {
          controller.showMoreValues();
        }}
      >
        Show More <ExpandMoreRounded />
      </Button>
    );
  };

  const getShowLess = () => {
    return (
      <Button
        color="secondary"
        variant="outlined"
        title="Show Less"
        onClick={() => {
          controller.showLessValues();
        }}
      >
        Show Less <ExpandLessRounded />
      </Button>
    );
  };
  if (!state.values.length) {
    return (
      <div className="facet">
        <h3>{props.title}</h3>
        <div>No facet values</div>
      </div>
    );
  }

  return (
    <div className="facet">
      <span className="specialties-title">{props.title}</span>
      {state.values.map((value) => (
        <div key={value.value}>
          <input
            type="checkbox"
            checked={controller.isValueSelected(value)}
            onChange={() => controller.toggleSelect(value)}
            disabled={state.isLoading}
          />
          {value.value}
          {props.showCount ? value.numberOfResults : <></>}
        </div>
      ))}
      <div className="show-more-less-wrapper">
        {state.canShowMoreValues && getShowMore()}
        {state.canShowLessValues && getShowLess()}
      </div>
    </div>
  );
};
