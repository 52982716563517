import React from 'react';
import {
  CoreEngine,
  CategoryFacet as HeadlessCategoryFacet,
  Facet as HeadlessFacet,
} from '@coveo/headless';
import { CategoryFacet } from './category-facet';
import './index.scss';

interface DependentFacetMapping {
  dependentFacet: HeadlessFacet;
  dependentValues: string[];
  title: string;
}

export const MultipleValueDependentFacet: React.FunctionComponent<{
  engine: CoreEngine;
  parentFacet: HeadlessCategoryFacet;
  dependentFacets: DependentFacetMapping[];
}> = ({ parentFacet, dependentFacets }) => {
  return (
    <div className="multi-facet-wrapper">
      <div className="parent-facet-wrapper">
        <span className="location-title">Locations</span>
        <CategoryFacet controller={parentFacet} dependentFacets={dependentFacets} />
      </div>
    </div>
  );
};
