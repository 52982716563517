import { Facet as HeadlessFacet } from '@coveo/headless';
import React, { useEffect, useState, FunctionComponent } from 'react';

interface FacetProps {
  controller: HeadlessFacet;
}

export const Facet: FunctionComponent<FacetProps> = (props) => {
  const { controller } = props;
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  if (!state.values.length) {
    return <div>No facet values</div>;
  }

  return (
    <div className="dependent-facet">
      {state.values.map((value) => (
        <div key={value.value}>
          <input
            type="checkbox"
            checked={controller.isValueSelected(value)}
            onChange={() => controller.toggleSelect(value)}
            disabled={state.isLoading}
          />
          {value.value}
        </div>
      ))}
    </div>
  );
};
